import PropTypes from 'prop-types';
import cx from 'classnames';

const FormTitle = ({ as: Component, children, className }) => (
  <Component className={cx('text-xl font-medium leading-tight', className)}>{children}</Component>
);

FormTitle.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({ render: PropTypes.func }),
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
};

FormTitle.defaultProps = {
  as: 'h2',
  children: null,
  className: 'mb-6',
};

export default FormTitle;
