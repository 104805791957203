import { useMemo, useEffect } from 'react';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import cx from 'classnames';

import redirect from 'lib/redirect';
import { GET_USER, useAuth } from 'context/Auth';
import useBreakpoint from 'hooks/useBreakpoint';
import withApollo from 'components/withApollo';
import DefaultLayout from 'components/layouts/DefaultLayout';
import LoginForm from 'components/global/LoginForm';
import Link from 'components/common/Link';
import LogoKey from '../svg/logo-key.svg';
import Tagline from '../svg/tagline-run-walk.svg';

export const DEFAULT_LOGIN_REDIRECT = '/account';

const LoginPage = () => {
  const router = useRouter();
  const { isLoggedIn } = useAuth();

  const lgScreen = useBreakpoint('lg');
  const Layout = useMemo(
    () =>
      lgScreen
        ? ({ className, ...props }) => (
            <div className={cx('min-h-screen flex flex-col items-stretch', className)} {...props} />
          )
        : DefaultLayout,
    [lgScreen]
  );

  useEffect(() => {
    if (isLoggedIn) {
      router.replace(router.query.redirectUrl ?? DEFAULT_LOGIN_REDIRECT);
    }
  }, [isLoggedIn, router]);

  return (
    <Layout className="bg-white md:bg-gray-100">
      <NextSeo title="Sign In" />

      <div className="grow grid grid-cols-1 lg:grid-cols-2">
        <div className="hidden lg:block">
          <div
            className="fixed inset-y-0 left-0 w-1/2 flex flex-col justify-between bg-gray-700 bg-cover bg-center"
            style={{
              backgroundImage:
                'url("https://res.cloudinary.com/pledge-it/image/upload/v1637610357/mako/application/bg-login.jpg")',
            }}
          >
            <div className="py-8 px-12">
              <Link href="/">
                <LogoKey className="fill-white h-[1.625rem]" />
              </Link>
            </div>
            <div className="relative p-12">
              <div className="absolute inset-0 bg-gradient-to-b from-transparent to-gray-900 opacity-75" />
              <Tagline className="relative fill-current text-white w-4/5 max-h-12 mx-auto" />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="md:flex md:items-center grow">
            <div className="container">
              <div className="sm:mx-auto sm:max-w-md">
                <LoginForm
                  initialEmail={router.query.email}
                  forgotPasswordParams={{
                    redirectUrl: router.query.redirectUrl ?? DEFAULT_LOGIN_REDIRECT,
                  }}
                  title={router.query.resetSent === 'true' ? 'Welcome back' : undefined}
                  allowFacebookLogin
                />
              </div>
            </div>
          </div>
          <div className="hidden lg:block shrink-0 text-center pb-4">
            <p className="text-sm text-gray-500 max-w-sm mx-auto">
              Copyright &copy;{new Date().getFullYear()} • All rights reserved{' '}
            </p>
            <p className="text-sm text-gray-500 max-w-sm mx-auto">
              <Link
                href="/privacy"
                external
                className="underline hover:no-underline hover:text-gray-600"
              >
                Privacy Policy
              </Link>{' '}
              •{' '}
              <Link
                href="/tos"
                external
                className="underline hover:no-underline hover:text-gray-600"
              >
                Terms of Service
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

// Server-side redirect if already logged-in
LoginPage.getInitialProps = async ({ apolloClient, query, res }) => {
  const redirectTarget = query.redirectUrl ? { url: query.redirectUrl } : DEFAULT_LOGIN_REDIRECT;

  try {
    const response = await apolloClient.query({ query: GET_USER });
    if (response?.data?.me) redirect(res, redirectTarget);
  } catch (err) {
    console.error(err);
  }

  return {};
};

export default withApollo(LoginPage);
